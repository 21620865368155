<template>
  <v-card
    flat
    class="pa-2 ma-2"
  >
    <v-card
      flat
      class="pa-2 ma-2"
    >
      <v-row>
        <v-col
          cols="12"
          md="12"
          class="pa-0 ma-0"
        >
          <v-autocomplete
            v-model="selectedClient" 
            :items="clientNameList"
            item-text="organization_name"
            label="Select client Name"
            required
            return-object
            @change="ClientNameListChanged"
            class="pa-0 ma-0"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-card>
    <v-card
      flat
      class="pa-2 ma-2"
      v-if="invoiceTableData.length>0"
    >
      <v-row>
        <v-col
          cols="12"
          md="8"
        >
          <v-data-table
            :headers="invoiceHeaders"
            :items="invoiceTableData"
            class="table-rounded"
            hide-default-footer
          >
          </v-data-table>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-menu
            ref="calendarMenu"
            v-model="calendarMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="invoiceDate"
                label="Invoice Date"
                readonly
                v-bind="attrs"
                v-on="on"
                :disabled="true"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="invoiceDate"
              no-title
              scrollable
              @input="calendarMenu = false"
            >
            </v-date-picker>
          </v-menu>
          <v-radio-group v-model="contactRadios">
            <template v-slot:label>
              <div>Select Contact Person:</div>
            </template>
            <v-radio
              v-for="person in selectedClient.contact_persons"
              :value="person.id"
              :label="person.person_name"
              :key="person.id"
            >
            </v-radio>
          </v-radio-group>
          <v-radio-group v-model="addressRadios">
            <template v-slot:label>
              <div>Select Address:</div>
            </template>
            <v-radio
              v-for="address in selectedClient.addresses"
              :value="address.id"
              :label="address.city + ', ' + address.state_name"
              :key="address.id"
            >
            </v-radio>
          </v-radio-group>
          <v-overlay
            :absolute="absolute"
            :value="submitDisabled"
            v-if="submitDisabled"
          >
            <v-progress-circular
              :size="70"
              :width="7"
              color="purple"
              indeterminate
              v-if="submitDisabled"
            ></v-progress-circular>
          </v-overlay>
          <v-btn :disabled="submitDisabled" color="primary" @click="onGenerateInvoice()">
              Generate Invoice
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-card
      flat
      class="pa-2 ma-2"
      v-if="showOrderList"
    >
      Select Orders to generate Invoice
      <v-data-table
        :headers="headers"
        :items="clientOrderList"
        @input="rowSelected()"
        class="table-rounded"
        v-model="selectedOrdersRows"
        show-select
        @toggle-select-all="selectAllToggle"
      >
        <template v-slot:item.createdAt="{ item }">
          {{ simpleFormatDate(item.createdAt) }}
        </template>
        
      </v-data-table>
    </v-card>

    <v-divider class="my-2" v-if="alertMsg.length > 1"></v-divider>
    
    <v-alert
      :type="alertType"
      v-if="alertMsg.length > 1"
      dismissible
    > 
      <span v-html="alertMsg"></span>
    </v-alert>
    <ConfirmDialog ref="confirm" />
    
  </v-card>
</template>

<script>
import { mdiCalendar } from '@mdi/js'
import { ref } from '@vue/composition-api'

import { listClient, listClientOrders, generateInvoice, getDownloadInvoiceURL } from "../../../services/apiCall.js";
import { simpleFormatDate, openExternalURL } from "../../../services/utils.js";

export default {
  components: {
      ConfirmDialog: () => import("../../common/ConfirmDialog"),
  },
  data: () => ({
    valid: true,
    showAlert: false,
    absolute: true,
    alertMsg: '',
    alertType: 'success',
    submitDisabled: false,
    selectedClient: '',
    showOrderList: false,
    invoiceDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    calendarMenu: false,
    selectedOrdersRows: [],
    clientNameList: [],
    clientOrderList: [],
    invoiceTableData: [],
    addressRadios: '',
    contactRadios: '',
    totalInvoiceAmount: 0,
    headers: [
      { text: 'Client Name', value: 'organization_name' },
      { text: 'Order Title', value: 'order_heading' },
      { text: 'PO number', value: 'po_number' },
      { text: 'Molecules', value: 'molecule_count' },
      { text: 'Order Date', value: 'createdAt' },
      { text: 'Order Status', value: 'current_status' },
    ],
    invoiceHeaders: [
      { text: 'No', value: 'sr_no' },
      { text: 'Order', value: 'order_heading' },
      { text: 'Molecules', value: 'no_of_mols' },
      { text: 'Rate', value: 'rate' },
      { text: 'Amount', value: 'mol_amount' },
      { text: 'GST', value: 'gst' },
      { text: 'Total', value: 'total' },
    ],
    icons: {
        mdiCalendar
      },
  }),
  setup() {
    return {
      listClient,
      listClientOrders,
      simpleFormatDate,
      generateInvoice,
      getDownloadInvoiceURL,
      openExternalURL,
    }
  },
  mounted:function(){
    this.init();
  },
    methods: {
    init() {
      // List clients
      this.listClient({ include_address: true, include_contact_person:true, include_contract: true })
        .then(async (res) => {
          //console.log('Client List:', res);
          if (res.data.status === 0) {
            this.clientNameList = res.data.result.client_list;
          } else {
            this.alertType = "error";
            this.alertMsg = res.data.error.error_message;
            await this.$refs.confirm.open("", this.alertMsg, { noconfirm: true } );
          }
        })
        .catch(async (err) => {
          this.alertType = "error";
          this.alertMsg = "List client failed" + ": " + err.toString();
          await this.$refs.confirm.open("", this.alertMsg, { noconfirm: true } );
        });
    },
    
    ClientNameListChanged() {
      this.invoiceTableData = [];
      this.selectedOrdersRows = [];
      if (this.selectedClient && this.selectedClient.organization_name.length > 1) {
        this.listClientOrders({ client_id: [ this.selectedClient.id ], include_molecule: true })
        .then(async (res) => {
          //console.log('list client orders called:', res);
          if (res.data.status === 0) {
            this.clientOrderList = res.data.result.order_list;
            // update molecule count for safer side
            this.clientOrderList = this.clientOrderList.map((x) => {
              x.molecule_count = x.molecule_list.length;
              return x;
            });
          } else {
            this.showAlert = true;
            this.alertType = "error";
            this.alertMsg = res.data.error.error_message;
            await this.$refs.confirm.open("", this.alertMsg, { noconfirm: true } );
          }
        })
        .catch(async (err) => {
          this.showAlert = true;
          this.alertType = "error";
          this.alertMsg = "List client orders failed" + ": " + err.toString();
          await this.$refs.confirm.open("", this.alertMsg, { noconfirm: true } );
        });

        this.showOrderList = true;
      } else {
        this.showOrderList = false;
      }
    },

    async rowSelected() {
      // check if already invoiced
      for (let idx=0; idx<this.selectedOrdersRows.length; idx++) {
        if (this.selectedOrdersRows[idx].current_status === 'Invoiced') {
          await this.$refs.confirm.open("", "Invoiced already generated for this order.", { noconfirm: true } );
          this.selectedOrdersRows = [];
          return;
        }
      }
      
      let cnt = 0;
      let totalTax = 0;
      let totalAmount = 0;
      let totalAmountWithTax = 0;
      this.invoiceTableData = this.selectedOrdersRows.map((x) => {
        let rate = x.unit_rate;
        let molAmount = x.molecule_count*rate;
        let taxAmount = molAmount*0.18;
        totalTax += taxAmount;
        totalAmount += molAmount;
        totalAmountWithTax += molAmount+taxAmount;
        cnt += 1;
        return {
          'sr_no': cnt,
          'order_heading': x.order_heading,
          'no_of_mols': x.molecule_count,
          'rate': rate,
          'mol_amount': molAmount,
          'gst': taxAmount,
          'total': molAmount + taxAmount,
        }
      });

      //add last row
      if(this.selectedOrdersRows.length > 0) {
        this.totalInvoiceAmount = totalAmountWithTax;
        this.invoiceTableData.push({ 'sr_no': '', 'no_of_mols': '', 'rate': '', 'mol_amount': totalAmount,'gst': totalTax, 'total': totalAmountWithTax });
      }
    },

    async onGenerateInvoice() {
      if (!this.contactRadios) {
        await this.$refs.confirm.open("", "Please select contact person to generate invoice.", { noconfirm: true } );
        return;
      }
      if (!this.addressRadios) {
        await this.$refs.confirm.open("", "Please select addres to generate invoice.", { noconfirm: true } );
        return;
      }
      if (this.totalInvoiceAmount === 0) {
        await this.$refs.confirm.open("", "Can not generate invoice for zero value", { noconfirm: true } );
        return;
      }
      this.submitDisabled = true;
      let orderIds = this.selectedOrdersRows.map((x) => x.id);
      this.generateInvoice({ 
        client_id: this.selectedClient.id,
        order_ids: orderIds,
        address_id: this.addressRadios,
        contact_person_id: this.contactRadios,
        invoice_date: this.invoiceDate })
        .then(async (res) => {
          //console.log('Generate invoice:', res);
          this.submitDisabled = false;
          if (res.data.status === 0) {
            this.showAlert = true;
            this.alertType = "success";
            this.alertMsg = res.data.result.message;
            await this.$refs.confirm.open("", this.alertMsg, { noconfirm: true } );
            for (let idx=0;idx<this.clientOrderList.length;idx++) {
              if (orderIds.indexOf(this.clientOrderList[idx].id) !== -1)
                this.clientOrderList[idx].current_status = 'Invoiced';
            }
            this.selectedOrdersRows = [];
            await this.downloadInvoice(res.data.result.invoice_number);
          } else {
            this.showAlert = true;
            this.alertType = "error";
            this.alertMsg = res.data.error.error_message;
            await this.$refs.confirm.open("", this.alertMsg, { noconfirm: true } );
          }
        })
        .catch(async (err) => {
          this.submitDisabled = false;
          this.showAlert = true;
          this.alertType = "error";
          this.alertMsg = "Invoice generation failed" + ": " + err.toString();
          await this.$refs.confirm.open("", this.alertMsg, { noconfirm: true } );
        });
    },

    async downloadInvoice(invoiceNumber) {
      let encodedInvoice = Buffer.from(invoiceNumber).toString('base64');
      let downloadURL = await this.getDownloadInvoiceURL({ invoice_number: encodedInvoice });
      this.openExternalURL(downloadURL);
    },

    resetForm() {
      this.$refs.form.reset();
      this.selectedClient = null;
    },

    selectAllToggle(props) {
        this.selectedOrdersRows = [];
    }
  }
}
</script>
