<template>
  <v-card>
    <!-- tabs -->
    <v-tabs
      v-model="tab"
      show-arrows
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.icon"
      >
        <v-icon
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <make-invoice></make-invoice>
      </v-tab-item>

      <v-tab-item>
        <update-payment></update-payment>
      </v-tab-item>

      <v-tab-item>
        <download-invoice></download-invoice>
      </v-tab-item>

      <v-tab-item>
        <proforma-invoice></proforma-invoice>
      </v-tab-item>

    </v-tabs-items>
  </v-card>
</template>

<script>
import { mdiAccountOutline, mdiLockOpenOutline, mdiInformationOutline, mdiDownload, mdiReceipt } from '@mdi/js'
import { ref } from '@vue/composition-api'
import MakeInvoice from './MakeInvoice.vue'
import UpdatePayment from './UpdatePayment.vue'
import DownloadInvoice from './DownloadInvoice.vue'
import ProformaInvoice from './ProformaInvoice.vue'

export default {
  components: {
    MakeInvoice,
    UpdatePayment,
    DownloadInvoice,
    ProformaInvoice,
  },
  setup() {
    const tab = ref('')

    // tabs
    const tabs = [
      { title: 'Make Invoice', icon: mdiAccountOutline },
      { title: 'Payment', icon: mdiInformationOutline },
      { title: 'Download Invoice', icon: mdiDownload },
      { title: 'Proforma Invoice', icon: mdiReceipt },
    ]

    return {
      tab,
      tabs,
      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
        mdiDownload,
      },
    }
  },
}
</script>
