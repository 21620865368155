<template>
  <v-card
    flat
    class="pa-2 ma-2"
  >
    <v-card
      flat
      class="pa-2 ma-2"
    >
      <v-row>
        <v-col
          cols="12"
          md="12"
          class="pa-0 ma-0"
        >
          <v-autocomplete
            v-model="selectedClient" 
            :items="clientNameList"
            item-text="organization_name"
            label="Select client Name to generate proforma invoice"
            required
            return-object
            @change="ClientNameListChanged"
            class="pa-0 ma-0"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-card>
    <v-card
      flat
      class="pa-2 ma-2"
      v-if="showProformaForm"
    >
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col
            cols="6"
            md="6"
            class="pa-0 mb-1"
          >
            <v-text-field
              v-model="description"
              placeholder="Description"
              label="Description"
              class="pa-0 mb-0"
            ></v-text-field>
          </v-col>
          <v-col
            cols="6"
            md="6"
            class="pl-5 mb-0"
          >
            <v-text-field
              v-model="moleculeCount"
              placeholder="Number of Molecules"
              label="Number of Molecules"
              type="number"
              :rules="[v => !!v || 'Number of Molecules required']"
              class="pa-0 mb-0"
            ></v-text-field>
          </v-col>
          <v-col
            cols="6"
            md="6"
            class="pa-0 mb-1"
          >
            <v-text-field
              v-model="poNumber"
              placeholder="PO Number"
              label="PO Number"
              :rules="[v => !!v || 'PO Number required']"
              class="pa-0 mb-0"
            ></v-text-field>
          </v-col>
          <v-col
            cols="6"
            md="6"
            class="pa-0 pl-5 ma-0"
          >
            <v-menu
              ref="poDateMenu"
              v-model="poDateMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="poDate"
                  label="Select PO date"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="poDate"
                no-title
                scrollable
                @input="poDateMenu = false"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col
            cols="6"
            md="6"
            class="pa-0 ma-0"
          >
            <v-menu
              ref="calendarMenu"
              v-model="calendarMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="invoiceDate"
                  label="Invoice Date"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :disabled="true"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="invoiceDate"
                no-title
                scrollable
                @input="calendarMenu = false"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col
            cols="6"
            md="6"
            class="pa-0 pl-5 mb-1"
          >
            <v-text-field
              v-model="unitRate"
              placeholder="Unit Rate of a molecule"
              label="Unit Rate"
              type="number"
              :rules="[v => !!v || 'Unit rate is required']"
              class="pa-0 mb-1"
              readonly
            ></v-text-field>
          </v-col>
          <v-col
            cols="6"
            md="6"
            class="pa-0 pl-5 ma-0"
          >
            <v-radio-group v-model="contactRadios">
              <template v-slot:label>
                <div>Select Contact Person:</div>
              </template>
              <v-radio
                v-for="person in selectedClient.contact_persons"
                :value="person.id"
                :label="person.person_name"
                :key="person.id"
              >
              </v-radio>
            </v-radio-group>
          </v-col>
          <v-col
            cols="6"
            md="6"
            class="pa-0 pl-5 ma-0"
          >
            <v-radio-group v-model="addressRadios">
              <template v-slot:label>
                <div>Select Address:</div>
              </template>
              <v-radio
                v-for="address in selectedClient.addresses"
                :value="address.id"
                :label="address.city + ', ' + address.state_name"
                :key="address.id"
              >
              </v-radio>
            </v-radio-group>
          </v-col>
          <v-col
            cols="12"
            md="12"
            class="pa-0 mb-1"
          >
            <v-overlay
              :absolute="absolute"
              :value="submitDisabled"
              v-if="submitDisabled"
            >
              <v-progress-circular
                :size="70"
                :width="7"
                color="purple"
                indeterminate
                v-if="submitDisabled"
              ></v-progress-circular>
            </v-overlay>
            <v-btn :disabled="submitDisabled" color="primary" @click="onGenerateProformaInvoice()">
                Generate Proforma Invoice
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <v-card
      flat
      class="pa-2 ma-2"
    >
      <v-data-table
        :headers="proformaInvoiceHeaders"
        :items="proformaInvoiceList"
        class="table-rounded"
        :search="search"
        :custom-filter="filterInvoices"
      >
        <template v-slot:item.invoice_date="{ item }">
          {{ simpleFormatDate(item.invoice_date) }}
        </template>
        <template v-slot:item.po_date="{ item }">
          {{ simpleFormatDate(item.po_date) }}
        </template>
        <template v-slot:top>
          <v-text-field
            v-model="search"
            label="Search"
            class="mx-4"
          ></v-text-field>
        </template>
        <template v-slot:item.id="{ item }">
          <v-icon
            color="green darken-2"
            @click="downloadProformaInvoice(item.invoice_number)"
          >
            {{ icons.mdiDownload }} 
          </v-icon>
        </template>      
      </v-data-table>
    </v-card>
    <v-card
      flat
      class="pa-2 ma-2"
    >
    </v-card>

    <v-divider class="my-2" v-if="alertMsg.length > 1"></v-divider>
    
    <v-alert
      :type="alertType"
      v-if="alertMsg.length > 1"
      dismissible
    > 
      <span v-html="alertMsg"></span>
    </v-alert>
    <ConfirmDialog ref="confirm" />
    
  </v-card>
</template>

<script>
import { mdiCalendar, mdiDownload } from '@mdi/js'
import { ref } from '@vue/composition-api'

import { listClient, generateProformaInvoice, getDownloadProformaInvoiceURL, listProformaInvoices } from "../../../services/apiCall.js";
import { simpleFormatDate, openExternalURL } from "../../../services/utils.js";

export default {
  components: {
      ConfirmDialog: () => import("../../common/ConfirmDialog"),
  },
  data: () => ({
    valid: true,
    showAlert: false,
    alertMsg: '',
    alertType: 'success',
    absolute: true,
    submitDisabled: false,
    selectedClient: '',
    showProformaForm: false,
    moleculeCount: 0,
    description: '',
    unitRate: 0,
    poDateMenu: false,
    poNumber: '',
    poDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    invoiceDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    calendarMenu: false,
    clientNameList: [],
    proformaInvoiceTableData: [],
    addressRadios: '',
    contactRadios: '',
    totalInvoiceAmount: 10,
    search: '',
    proformaInvoiceList: [],
    proformaInvoiceHeaders: [
      { text: 'Invoice Number', value: 'invoice_number' },
      { text: 'Client', value: 'client_name' },
      { text: 'PO Number', value: 'po_number' },
      { text: 'PO Date', value: 'po_date' },
      { text: 'Invoice Date', value: 'invoice_date' },
      { text: 'Total Amount', value: 'invoice_amount' },
      { text: 'Download', value: 'id' },
    ],
    icons: {
        mdiCalendar,
        mdiDownload,
      },
  }),
  setup() {
    return {
      listClient,
      openExternalURL,
      simpleFormatDate,
      generateProformaInvoice,
      getDownloadProformaInvoiceURL,
      listProformaInvoices,
    }
  },
  mounted:function(){
    this.init();
  },
    methods: {
    init() {
      // List clients
      this.listClient({ include_address: true, include_contact_person:true, include_contract: true })
        .then(async (res) => {
          //console.log('Client List:', res);
          if (res.data.status === 0) {
            this.clientNameList = res.data.result.client_list;
          } else {
            this.alertType = "error";
            this.alertMsg = res.data.error.error_message;
            await this.$refs.confirm.open("", this.alertMsg, { noconfirm: true } );
          }
        })
        .catch(async (err) => {
          this.alertType = "error";
          this.alertMsg = "List client failed" + ": " + err.toString();
          await this.$refs.confirm.open("", this.alertMsg, { noconfirm: true } );
        });

        this.getListOfProformaInvoices();
    },

    getListOfProformaInvoices() {
      this.listProformaInvoices()
        .then(async (res) => {
          if (res.data.status === 0) {
            this.proformaInvoiceList = res.data.result.invoice_list;
          } else {
            this.alertType = "error";
            this.alertMsg = res.data.error.error_message;
            await this.$refs.confirm.open("", this.alertMsg, { noconfirm: true } );
          }
        })
        .catch(async (err) => {
          this.alertType = "error";
          this.alertMsg = "List proforma invoices failed" + ": " + err.toString();
          await this.$refs.confirm.open("", this.alertMsg, { noconfirm: true } );
        });
    },
    
    ClientNameListChanged() {
      this.proformaInvoiceTableData = [];
      this.showProformaForm = true;
      //set unit rate
      this.unitRate = this.selectedClient.current_contract.unit_rate;
    },

    
    async onGenerateProformaInvoice() {
      await this.$refs.form.validate();
      if (!this.valid) return;
      if (!this.contactRadios) {
        await this.$refs.confirm.open("", "Please select contact person to generate invoice.", { noconfirm: true } );
        return;
      }
      if (!this.addressRadios) {
        await this.$refs.confirm.open("", "Please select addres to generate invoice.", { noconfirm: true } );
        return;
      }
      if (this.totalInvoiceAmount === 0) {
        await this.$refs.confirm.open("", "Can not generate invoice for zero value", { noconfirm: true } );
        return;
      }

      let totalAmount = this.moleculeCount*this.unitRate;
      let totalTax = totalAmount*0.18;
      let invAmount = totalAmount + totalTax;

      let isConfirm = await this.$refs.confirm.open("Invoice Summary", "Molecules: "+this.moleculeCount + 
                      "<br/>Rate: "+this.unitRate + 
                      "<br/>Total Amount: " + totalAmount + 
                      "<br/>Total Tax: "+ totalTax + 
                      "<br/>Invoice Amount: "+ invAmount
                      );

      if (!isConfirm) return;

      this.submitDisabled = true;
      this.generateProformaInvoice({ 
        client_id: this.selectedClient.id,
        description: this.description?this.description:"",
        address_id: this.addressRadios,
        contact_person_id: this.contactRadios,
        po_number: this.poNumber,
        po_date: this.poDate,
        no_of_molecules: this.moleculeCount,
        unit_rate: this.unitRate,
        invoice_date: this.invoiceDate })
        .then(async (res) => {
          //console.log('Generate invoice:', res);
          this.submitDisabled = false;
          if (res.data.status === 0) {
            this.showAlert = true;
            this.alertType = "success";
            this.alertMsg = res.data.result.message;
            await this.$refs.confirm.open("", this.alertMsg, { noconfirm: true } );
            await this.downloadProformaInvoice(res.data.result.invoice_number);
            this.resetForm();
            this.getListOfProformaInvoices();
          } else {
            this.showAlert = true;
            this.alertType = "error";
            this.alertMsg = res.data.error.error_message;
            await this.$refs.confirm.open("", this.alertMsg, { noconfirm: true } );
          }
        })
        .catch(async (err) => {
          this.submitDisabled = false;
          this.showAlert = true;
          this.alertType = "error";
          this.alertMsg = "Invoice generation failed" + ": " + err.toString();
          await this.$refs.confirm.open("", this.alertMsg, { noconfirm: true } );
        });
    },

    async downloadProformaInvoice(invoiceNumber) {
      console.log('invoiceNumber:', invoiceNumber);
      let encodedInvoice = Buffer.from(invoiceNumber).toString('base64');
      console.log('encodedInvoice:', encodedInvoice);
      let downloadURL = await this.getDownloadProformaInvoiceURL({ invoice_number: encodedInvoice });
      console.log('downloadURL:', downloadURL);
      this.openExternalURL(downloadURL);
    },

    filterInvoices(value, search, item) {
      // console.log('Filter',value, search, item);
      return value != null && search != null && typeof value === 'string' && 
        value.toString().indexOf(search) !== -1;
    },

    resetForm() {
      this.$refs.form.reset();
      this.poDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
      this.invoiceDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
      this.selectedClient = null;
      this.showProformaForm = false;
    },
  }
}
</script>
