<template>
  <v-card
    flat
    class="pa-2 ma-2"
  >
    <v-card
      flat
      class="pa-2 ma-2"
    >
      <v-row>
        <v-col
          cols="12"
          md="12"
          class="pa-0 ma-0"
        >
          <v-autocomplete
            v-model="selectedClient" 
            :items="clientNameList"
            item-text="organization_name"
            label="Select client Name"
            required
            return-object
            @change="ClientNameListChanged"
            class="pa-0 ma-0"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-card>
    <v-card
      flat
      class="pa-2 ma-2"
    >
      <v-row>
        <v-col
          cols="12"
          md="12"
          class="pa-0 ma-0"
        >
          <v-autocomplete
            v-model="selectedInvoice" 
            :items="invoiceList"
            item-text="invoice_number"
            label="Select invoice"
            required
            return-object
            @change="invoiceListChanged"
            class="pa-0 ma-0"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-card>
    <v-card
      flat
      class="pa-2 ma-2"
      v-if="showPaymentUpdateForm"
    >
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col
            cols="12"
            md="12"
            class="pa-0 mb-1"
          >
            <v-text-field
              v-model="receivedAmount"
              placeholder="Received Amount"
              :label= "receivedAmountLabel"
              type="number"
              :rules="[v => !isNaN(parseFloat(v)) && v >= 0 || 'Received Amount is required and positive']"
              class="pa-0 mb-1"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="12"
            class="pa-0 mb-1"
          >
            <v-text-field
              v-model="tdsAmount"
              placeholder="TDS Amount"
              :label="tdsAmountLabel"
              type="number"
              :rules="[v => !isNaN(parseFloat(v)) && v >= 0 || 'TDS Amount is positive number']"
              class="pa-0 mb-1"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="12"
            class="pa-0 mb-1"
          >
             <v-checkbox
              v-model="isTDSReceived"
              label="TDS Received"
            ></v-checkbox>
          </v-col>

          <v-col
            cols="12"
            md="12"
            class="pa-0 mb-1"
          >
          <v-menu
            ref="calendarMenu"
            v-model="calendarMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="tdsReceivedDate"
                label="TDS Received Date"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
              <v-date-picker
                v-model="tdsReceivedDate"
                no-title
                scrollable
                @input="calendarMenu = false"
              >
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col
            offset-md="1"
            cols="12"
          >
            <v-btn :disabled="submitDisabled || !valid" color="primary" @click="savePayment">
              Update Payment
            </v-btn>
            <v-btn
              type="reset"
              class="mx-2"
            >
              Reset
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <v-card
      flat
      class="pa-2 ma-2"
    >
      
    </v-card>

    <v-divider class="my-2" v-if="alertMsg.length > 1"></v-divider>
    
    <v-alert
      :type="alertType"
      v-if="alertMsg.length > 1"
      dismissible
    > 
      <span v-html="alertMsg"></span>
    </v-alert>
    <ConfirmDialog ref="confirm" />
    
  </v-card>
</template>

<script>
import { mdiCalendar } from '@mdi/js'
import { ref } from '@vue/composition-api'

import { listClient, listInvoices, updateInvoicePayment, fetchInvoicePayment } from "../../../services/apiCall.js";

export default {
  components: {
      ConfirmDialog: () => import("../../common/ConfirmDialog"),
  },
  data: () => ({
    valid: true,
    showAlert: false,
    alertMsg: '',
    alertType: 'success',
    submitDisabled: false,
    selectedClient: '',
    clientNameList: [],
    selectedInvoice: '',
    invoiceList: [],
    showPaymentUpdateForm: false,
    tdsReceivedDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    calendarMenu: false,
    receivedAmount: 0,
    tdsAmount: 0,
    isTDSReceived: false,
    receivedAmountLabel: 'Received Amount ',
    tdsAmountLabel: 'TDS Amount ',
    
  }),
  setup() {
    return {
      listClient,
      listInvoices,
      updateInvoicePayment,
      fetchInvoicePayment,
    }
  },
  mounted:function(){
    this.init();
  },
    methods: {
    init() {
      // List clients
      this.listClient()
        .then(async (res) => {
          //console.log('Client List:', res);
          if (res.data.status === 0) {
            this.clientNameList = res.data.result.client_list;
          } else {
            this.alertType = "error";
            this.alertMsg = res.data.error.error_message;
            await this.$refs.confirm.open("", this.alertMsg, { noconfirm: true } );
          }
        })
        .catch(async (err) => {
          this.alertType = "error";
          this.alertMsg = "List client failed" + ": " + err.toString();
          await this.$refs.confirm.open("", this.alertMsg, { noconfirm: true } );
        });
    },

    ClientNameListChanged() {
      if (this.selectedClient && this.selectedClient.organization_name.length > 1) {
        this.receivedAmount = null;
        this.tdsAmount = null;
        this.isTDSReceived = false;
        this.tdsReceivedDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
        this.listInvoices({ client_id: this.selectedClient.id })
        .then(async (res) => {
          if (res.data.status === 0) {
            this.invoiceList = res.data.result.invoice_list;
          } else {
            this.alertType = "error";
            this.alertMsg = res.data.error.error_message;
            await this.$refs.confirm.open("", this.alertMsg, { noconfirm: true } );
          }
        })
        .catch(async (err) => {
          this.alertType = "error";
          this.alertMsg = "List invoices failed" + ": " + err.toString();
          await this.$refs.confirm.open("", this.alertMsg, { noconfirm: true } );
        });
      }
    },

    invoiceListChanged() {
      if (this.selectedInvoice && this.selectedInvoice.invoice_number.length > 1) {
        this.showPaymentUpdateForm = true;
        //fetch payment if available
        this.receivedAmount = null;
        this.tdsAmount = null;
        this.isTDSReceived = false;
        this.tdsReceivedDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
        let expectedAmount = this.selectedInvoice.invoice_amount - this.selectedInvoice.tax_amount;
        this.receivedAmountLabel = 'Received Amount (Expected: ' + expectedAmount + ', with 10% tds: ' + expectedAmount*0.9 + ', with 2% tds: ' + expectedAmount*0.98 + ')';
        this.tdsAmountLabel = 'TDS Amount (10% = ' + expectedAmount*0.1 + ', 2% = ' + expectedAmount*0.02 + ')';
        this.fetchInvoicePayment({ invoice_id: this.selectedInvoice.id})
        .then(async (res) => {
        if (res.data.status === 0) {
          let paymentDetails = res.data.result.payment_details;
          this.receivedAmount = paymentDetails.received_amount;
          this.tdsAmount = paymentDetails.tds_amount;
          this.isTDSReceived = paymentDetails.is_tds_received;
          this.tdsReceivedDate = paymentDetails.tds_date;
        }
      })
      .catch(async (err) => {
        this.alertType = "error";
        this.alertMsg = "fetch invoice payment failed" + ": " + err.toString();
        await this.$refs.confirm.open("", this.alertMsg, { noconfirm: true } );
      });
      }
    },

    savePayment() {
      let updateRec = {
        invoice_id: this.selectedInvoice.id,
        received_amount: this.receivedAmount,
        tds_amount: this.tdsAmount,
        is_tds_received: this.isTDSReceived,
        tds_date: this.tdsReceivedDate,
      }
      this.updateInvoicePayment(updateRec)
      .then(async (res) => {
        if (res.data.status === 0) {
          this.alertType = "success";
          this.alertMsg = res.data.result.message;
          await this.$refs.confirm.open("", this.alertMsg, { noconfirm: true } );
          this.resetForm();
        } else {
          this.alertType = "error";
          this.alertMsg = res.data.error.error_message;
          await this.$refs.confirm.open("", this.alertMsg, { noconfirm: true } );
        }
      })
      .catch(async (err) => {
        this.alertType = "error";
        this.alertMsg = "List invoices failed" + ": " + err.toString();
        await this.$refs.confirm.open("", this.alertMsg, { noconfirm: true } );
      });
    },

    resetForm() {
      this.$refs.form.reset();
      this.selectedClient = null;
      this.selectedInvoice = null;
      this.showPaymentUpdateForm = false;
    },

    
  }
}
</script>
