<template>
  <v-card
    flat
    class="pa-2 ma-2"
  >
    <v-card
      flat
      class="pa-2 ma-2"
    >
      <v-data-table
        :headers="invoiceHeaders"
        :items="invoiceList"
        class="table-rounded"
        :search="search"
        :custom-filter="filterInvoices"
      >
        <template v-slot:item.invoice_date="{ item }">
          {{ simpleFormatDate(item.invoice_date) }}
        </template>
        <template v-slot:top>
          <v-text-field
            v-model="search"
            label="Search"
            class="mx-4"
          ></v-text-field>
        </template>
        <template v-slot:item.id="{ item }">
          <v-icon
            color="green darken-2"
            @click="downloadInvoice(item)"
          >
            {{ icons.mdiDownload }} 
          </v-icon>
        </template>      
      </v-data-table>
    </v-card>

    <v-divider class="my-2" v-if="alertMsg.length > 1"></v-divider>
    
    <v-alert
      :type="alertType"
      v-if="alertMsg.length > 1"
      dismissible
    > 
      <span v-html="alertMsg"></span>
    </v-alert>
    <ConfirmDialog ref="confirm" />
    
  </v-card>
</template>

<script>
import { mdiCalendar, mdiDownload } from '@mdi/js'
import { ref } from '@vue/composition-api'

import { listInvoices, getDownloadInvoiceURL } from "../../../services/apiCall.js";
import { simpleFormatDate, openExternalURL } from "../../../services/utils.js";

export default {
  components: {
      ConfirmDialog: () => import("../../common/ConfirmDialog"),
  },
  data: () => ({
    valid: true,
    showAlert: false,
    alertMsg: '',
    alertType: 'success',
    submitDisabled: false,
    search: '',
    invoiceList: [],
    invoiceHeaders: [
      { text: 'Invoice Number', value: 'invoice_number' },
      { text: 'Client', value: 'client_name' },
      { text: 'Order', value: 'order_ids' },
      { text: 'Invoice Date', value: 'invoice_date' },
      { text: 'Amount', value: 'amount' },
      { text: 'Tax', value: 'tax_amount' },
      { text: 'Total Amount', value: 'invoice_amount' },
      { text: 'Download', value: 'id' },
    ],
    icons: {
        mdiCalendar,
        mdiDownload
      },
  }),
  setup() {
    return {
      listInvoices,
      simpleFormatDate,
      getDownloadInvoiceURL,
      openExternalURL,
    }
  },
  mounted:function(){
    this.init();
  },
    methods: {
    init() {
      // List clients
      this.listInvoices()
        .then(async (res) => {
          if (res.data.status === 0) {
            this.invoiceList = res.data.result.invoice_list;
            this.invoiceList = this.invoiceList.map((x) => {
              x['amount'] = x.invoice_amount - x.tax_amount;
              return x;
            });
          } else {
            this.alertType = "error";
            this.alertMsg = res.data.error.error_message;
            await this.$refs.confirm.open("", this.alertMsg, { noconfirm: true } );
          }
        })
        .catch(async (err) => {
          this.alertType = "error";
          this.alertMsg = "List invoices failed" + ": " + err.toString();
          await this.$refs.confirm.open("", this.alertMsg, { noconfirm: true } );
        });
    },

    async downloadInvoice(item) {
      let encodedInvoice = Buffer.from(item.invoice_number).toString('base64');
      let downloadURL = await this.getDownloadInvoiceURL({ invoice_number: encodedInvoice });
      this.openExternalURL(downloadURL);
    },

    filterInvoices(value, search, item) {
      // console.log('Filter',value, search, item);
      return value != null && search != null && typeof value === 'string' && 
        value.toString().indexOf(search) !== -1;
    }
    
  }
}
</script>
